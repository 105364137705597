import { Component } from "vue";

export const useBlockContainer = () => {
  const BlocksBlockProductList = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockProductList.vue"),
    ),
  );

  const BlocksBlockPlpTopBanner = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockPlpTopBanner.vue"),
    ),
  );

  const Block2ColSlider = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/Block2ColSlider.vue"),
    ),
  );

  const BlocksBlockProperties = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockProperties.vue"),
    ),
  );

  const BlockRecentlyView = computed(() =>
    defineAsyncComponent(
      () => import("@/components/pages/home/BlockRecentlyView.vue"),
    ),
  );

  const BlockAccordion = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockAccordion.vue"),
    ),
  );

  const BlockArticles = computed(() =>
    defineAsyncComponent(() => import("@/components/blocks/BlockArticles.vue")),
  );

  const BlockBestseller = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockBestseller.vue"),
    ),
  );

  const BlockComparison = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockComparison.vue"),
    ),
  );

  const BlockInstagram = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockInstagram.vue"),
    ),
  );

  const MarketingCollections = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/MarketingCollections.vue"),
    ),
  );

  const BlockMedia = computed(() =>
    defineAsyncComponent(() => import("@/components/blocks/BlockMedia.vue")),
  );

  const BlockPromoted = computed(() =>
    defineAsyncComponent(() => import("@/components/blocks/BlockPromoted.vue")),
  );

  const BlockRichText = computed(() =>
    defineAsyncComponent(() => import("@/components/blocks/BlockRichText.vue")),
  );

  const BlockSale = computed(() =>
    defineAsyncComponent(() => import("@/components/blocks/BlockSale.vue")),
  );

  const BlockWishlist = computed(() =>
    defineAsyncComponent(() => import("@/components/blocks/BlockWishlist.vue")),
  );

  const BlockCategoriesDefault = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockCategoriesDefault.vue"),
    ),
  );

  const BlockDefaultSlider = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockDefaultSlider.vue"),
    ),
  );

  const BlockTopSlider = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockTopSlider.vue"),
    ),
  );

  const BlockContactUs = computed(() =>
    defineAsyncComponent(
      () => import("@/components/pages/contact-us/BlockContactUs.vue"),
    ),
  );

  const CollectionCustomSlider = computed(() =>
    defineAsyncComponent(
      () => import("~/components/blocks/CustomBlocks/Home/HomeCollections.vue"),
    ),
  );

  const MapBlock = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/CustomBlocks/MapBlock.vue"),
    ),
  );

  const BlocksCustomBlocksFormContactUs = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/CustomBlocks/FormContactUs.vue"),
    ),
  );

  const BlockAbout = computed(() =>
    defineAsyncComponent(() => import("@/components/blocks/BlockAbout.vue")),
  );

  const BlockHeadImageSwiper = computed(() =>
    defineAsyncComponent(
      () => import("~/components/pages/home/BlockHeadImageSwiper.vue"),
    ),
  );

  const HomeBlockHomeSlides = computed(() =>
    defineAsyncComponent(
      () => import("~/components/pages/home/BlockHomeSlides.vue"),
    ),
  );

  const BlockHomeAlwaysEngaging = computed(() =>
    defineAsyncComponent(
      () => import("~/components/pages/home/BlockHomeAlwaysEngaging.vue"),
    ),
  );

  const BlockHomeMediaSlider = computed(() =>
    defineAsyncComponent(
      () => import("~/components/pages/home/BlockHomeMediaSlider.vue"),
    ),
  );

  const BlockHomeLabGrownDiamonds = computed(() =>
    defineAsyncComponent(
      () => import("~/components/pages/home/BlockHomeLabGrownDiamonds.vue"),
    ),
  );

  const BlockHomeYouPremierFineSlider = computed(() =>
    defineAsyncComponent(
      () => import("~/components/pages/home/BlockHomeYouPremierFineSlider.vue"),
    ),
  );

  const BlockEntityGrid = computed(() =>
    defineAsyncComponent(
      () => import("~/components/blocks/BlockEntityGrid.vue"),
    ),
  );

  const Block3Cols = computed(() =>
    defineAsyncComponent(() => import("~/components/blocks/Block3Cols.vue")),
  );

  const BlockHomeLabDiamondsTopSlider3Cols = computed(() =>
    defineAsyncComponent(
      () => import("~/components/blocks/BlockHomeLabDiamondsTopSlider.vue"),
    ),
  );

  const BlockCustomBlocksServiceBlock = computed(() =>
    defineAsyncComponent(
      () =>
        import(
          "~/components/blocks/CustomBlocks/ServiceBlock/ServiceBlock.vue"
        ),
    ),
  );

  const BlockPlpEntityFilter = computed(() =>
    defineAsyncComponent(
      () => import("@/components/blocks/BlockPlpEntityFilter.vue"),
    ),
  );

  const ServicesCustomSlider = computed(() =>
    defineAsyncComponent(
      () => import("~/components/blocks/CustomBlocks/ServicesCustomSlider.vue"),
    ),
  );

  const ServicesCustomGridSlider = computed(() =>
    defineAsyncComponent(
      () => import("~/components/blocks/CustomBlocks/ServicesCustomGridSlider.vue"),
    ),
  );

  interface ComponentsInterface {
    [key: string]: Component | string;
  }

  const defaultComponentOptions: ComponentsInterface = {
    catalog: markRaw(BlocksBlockProductList.value),
    accordion: markRaw(BlockAccordion.value),
    articles: markRaw(BlockArticles.value),
    bestseller: markRaw(BlockBestseller.value),
    categories: markRaw(BlockCategoriesDefault.value),
    comparison: markRaw(BlockComparison.value),
    entity: markRaw(BlocksBlockProperties.value),
    instagram: markRaw(BlockInstagram.value),
    "marketing-collections": markRaw(MarketingCollections.value),
    media: markRaw(BlockMedia.value),
    promoted: markRaw(BlockPromoted.value),
    "recently-viewed": markRaw(BlockRecentlyView.value),
    "rich-text-module": markRaw(BlockRichText.value),
    sale: markRaw(BlockSale.value),
    slider: markRaw(BlockDefaultSlider.value),
    "slider-2-columns": markRaw(Block2ColSlider.value),
    wishlist: markRaw(BlockWishlist.value),
    "top-slider": markRaw(BlockTopSlider.value),
    "plp-top-banner": markRaw(BlocksBlockPlpTopBanner.value),
  };

  const customComponentsOptions: ComponentsInterface = {
    "fine-jewelry-about": markRaw(BlockAbout.value),
    "plp-page-top-banner": markRaw(BlocksBlockPlpTopBanner.value), // TODO remove
    "home-header-slider": markRaw(BlockHeadImageSwiper.value),
    "home-slides": markRaw(HomeBlockHomeSlides.value), // TODO remove
    "home-always-engaging-slider": markRaw(BlockHomeAlwaysEngaging.value), // TODO change on home page
    "home-media-slider": markRaw(BlockHomeMediaSlider.value), // TODO remove
    "home-lab-grown-diamonds": markRaw(BlockHomeLabGrownDiamonds.value), // TODO change on home page
    "home-your-premier-fine-slider": markRaw(
      BlockHomeYouPremierFineSlider.value,
    ), // TODO change on home page
    "contact-us-block": markRaw(BlockContactUs.value),
    "entity-grid": markRaw(BlockEntityGrid.value),
    "fine-jewelry-two-slider": markRaw(Block3Cols.value),
    "home-lab-diamonds-top-slider": markRaw(
      BlockHomeLabDiamondsTopSlider3Cols.value,
    ),

    "collection-custom-slider": markRaw(CollectionCustomSlider.value),
    "location-map": markRaw(MapBlock.value),
    "form-contact-us": markRaw(BlocksCustomBlocksFormContactUs.value),
    "services-block": markRaw(BlockCustomBlocksServiceBlock.value),
    "plp-shop-by": markRaw(BlockPlpEntityFilter.value),
    "professional-concierge-jewelry-services": markRaw(ServicesCustomSlider.value),
    "professional-concierge-jewelry-services-grid": markRaw(ServicesCustomGridSlider.value),
  };

  function getComponent(name: string, identifier: string): Component | string {
    const componentMap = {
      "plp-shop-by-": "plp-shop-by",
    };

    for (const key in componentMap) {
      if (name.includes(key)) {
        name = componentMap[key];
        break;
      }
    }
    return (
      customComponentsOptions[name] || defaultComponentOptions[identifier] || ""
    );
  }

  return { defaultComponentOptions, customComponentsOptions, getComponent };
};
